// Vendor Files
import React from "react";
import { Container, Navbar, Nav } from 'react-bootstrap';


// Util
import MetaDecorator from "../util/MetaDecorator";
import { MetaStrings } from "../util/MetaStrings";

// Image Files
import logo from "../assets/logos/midwest-logo.png";

export default class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {
        if (this.props.sample) {
            alert(this.props.sample);
        }
    }

    render() {
        return (

            <Navbar collapseOnSelect expand="lg" variant="dark" fixed="top">

                {this.props.metaPage && 
                    <MetaDecorator
                        title={MetaStrings[this.props.metaPage].title}
                        description={MetaStrings[this.props.metaPage].description}
                        keywords={MetaStrings[this.props.metaPage].keywords}
                        url={MetaStrings[this.props.metaPage].url}
                        imageURL={MetaStrings[this.props.metaPage].imageURL}
                    >
                    </MetaDecorator>
                }
                <Container>
                    <Navbar.Brand href="/">
                        <img alt="BAPS Swaminarayan Sanstha, Chicago, IL" src={logo} className="title-image" />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
                        <Nav>
                            <Nav.Link href="/events">Upcoming Events</Nav.Link>
                            <Nav.Link href="https://www.baps.org/Global-Network/North-America/Chicago/Visitor-Info.aspx" target="_blank">Visitor Information</Nav.Link>
                            <Nav.Link href="https://www.baps.org/chicago" target="_blank">About Us</Nav.Link>
                        </Nav>

                        {/* Crawling Links for pages on website */}
                        <Container className="hideNavLinks">
                            <Nav.Link href="/timelesshinduwisdom">THW</Nav.Link>
                            <Nav.Link href="/thw">THW</Nav.Link>
                            <Nav.Link href="/student-activities">Student Activities</Nav.Link>
                        </Container>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        );
    }
}