// Vendor Files
import React from "react";
import { Container, Row, Col, Navbar, Nav } from 'react-bootstrap';
import { useParams } from "react-router";
import _ from "lodash";

// Image Files
import logo from "../assets/images/Adhiveshan/logo2.png";
import floral2 from "../assets/images/Adhiveshan/floral2.png";
import mukpathPath1 from "../assets/images/Adhiveshan/mukpath-path1.png";
import mukpathPath2 from "../assets/images/Adhiveshan/mukpath-path2.png";
import mukpathDummy from "../assets/images/Adhiveshan/mukpath-comp.jpeg";

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

class MukpathView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            viewType: 1,
            participantsList: [],
            gender: "",

            participantsGrouped: {},
            currentBatch: [],
            currentIndex: 0,
            currentPushpaIndex: 0,
            pushpaCounts: [],
            countTwenty: 0,
            // New state for rotation
            currentTwentyIndex: 0,
            rotatingTwenty: [],

            showList: false,
            showImage: false
        };
        this.showNextBatch = this.showNextBatch.bind(this);
        this.rotateTwentyParticipants = this.rotateTwentyParticipants.bind(this);
        this.fetchAll = _.debounce(this.fetchAll.bind(this), 30000, { leading: true, trailing: false });
    }

    componentDidMount() {
        if (window.location.href.indexOf("#e-leaderboard") > -1) {
            this.setState({ viewType: 1 });
            this.fetchTop5("Male");
            // Fetch top 5 every 10 minutes
            this.fetchTop5Interval = setInterval(() => this.fetchTop5("Male"), 300000);
            // Toggle slide every 35 seconds
            this.toggleSlideInterval = setInterval(() => this.toggleSlide(), 35000);
        } 
        else if (window.location.href.indexOf("#i-leaderboard") > -1) {
            this.setState({ viewType: 2 });
            this.fetchTop5("Female");
            // Fetch top 5 every 10 minutes
            this.fetchTop5Interval = setInterval(() => this.fetchTop5("Female"), 300000);
            // Toggle slide every 35 seconds
            this.toggleSlideInterval = setInterval(() => this.toggleSlide(), 35000);
        } 
        else if (window.location.href.indexOf("#e-mukpath") > -1) {
            this.setState({ viewType: 3 });
            this.fetchAll("Male");
        } 
        else if (window.location.href.indexOf("#i-mukpath") > -1) {
            this.setState({ viewType: 4 });
            this.fetchAll("Female");
        }
    }
    
    componentWillUnmount() {
        if (this.fetchTop5Interval) {
            clearInterval(this.fetchTop5Interval);
        }
        if (this.toggleSlideInterval) {
            clearInterval(this.toggleSlideInterval);
        }
    }    

    toggleSlide() {
        this.setState({showImage: !this.state.showImage});
    }

    fetchTop5(gender) {
        this.setState({ gender: gender });

        fetch(
            "https://sheets.googleapis.com/v4/spreadsheets/1NAAAsBXuK2gm8Za35kYaZy4fS141zowuwagkL6G6ABA/values/Master%20Sheet?key=AIzaSyBv5LPMYVjJ0gttyVyROpQD5xO0nvMy2So"
        )
        .then((res) => res.json())
        .then(
            (result) => {
                if (result.values && result.values.length > 1) {
                    let participants = result.values;
                    participants.shift();

                    // Filter gender and valid pushpa count
                    participants = participants.filter(person => person[4] === gender && Number(person[5]) > 0);

                    // Sort by name
                    participants.sort((a, b) => a[0].localeCompare(b[0]));

                    // Sort by Pushpa count (Descending)
                    participants.sort((a, b) => Number(b[5]) - Number(a[5]));

                    // Get top 5
                    let topParticipants = participants.slice(0, 5);

                    // Count participants with 20 pushpa
                    let countTwenty = topParticipants.filter(person => Number(person[5]) === 20).length;

                    // Calculate the final count based on the formula
                    let finalCount = Math.min(5 - countTwenty + 1, 5);
                    if (countTwenty > 3) finalCount = countTwenty;
                    participants = participants.slice(0, finalCount);

                    // Set state
                    this.setState({
                        participantsList: participants,
                        showList: true,
                        countTwenty: countTwenty,
                        currentTwentyIndex: 0,
                        rotatingTwenty: countTwenty > 3 ? participants.slice(0, 3) : participants
                    });

                    // Start rotation if countTwenty > 3
                    if (countTwenty > 3) {
                        if (this.rotateTwentyInterval) clearInterval(this.rotateTwentyInterval);
                        this.rotateTwentyInterval = setInterval(this.rotateTwentyParticipants, 20000);
                    }
                }
            })
            .catch(() => {
                this.setState({ showList: false });
            });
    }

    rotateTwentyParticipants() {
        const { participantsList, countTwenty, currentTwentyIndex } = this.state;
        if (countTwenty <= 3) return; // No rotation needed if 3 or fewer
    
        let newIndex = currentTwentyIndex + 3;
    
        // If we've exhausted the list, restart from the beginning
        if (newIndex >= countTwenty) {
            newIndex = 0;
        }
    
        const newRotating = participantsList.slice(newIndex, newIndex + 3);
    
        this.setState({
            rotatingTwenty: newRotating,
            currentTwentyIndex: newIndex
        });
    }       

    fetchAll(gender) {
        this.setState({ gender: gender, participantsGrouped: {}, currentBatch: [], currentIndex: 0, currentPushpaIndex: 0, pushpaCounts: [] });
        // Fetch all Mukpath
        fetch(
            "https://sheets.googleapis.com/v4/spreadsheets/1NAAAsBXuK2gm8Za35kYaZy4fS141zowuwagkL6G6ABA/values/Master%20Sheet?key=AIzaSyBv5LPMYVjJ0gttyVyROpQD5xO0nvMy2So"
        )
        .then((res) => res.json())
        .then(
            (result) => {
                if (result.values && result.values.length > 1) {
                    let participants = result.values;
                    participants.shift();

                    // Filter only gender entries
                    participants = participants.filter(person => person[4] === gender && Number(person[5]) > 0);

                    // Sort by the 2nd element (Name) in descending order
                    participants.sort((a, b) => a[0].localeCompare(b[0]));

                    // Sort by the 3rd element (Pushpa Completed) in descending order
                    participants.sort((a, b) => Number(b[5]) - Number(a[5]));

                    // Remove the top 5 or based on formula
                    let countTwenty = participants.filter(person => Number(person[5]) === 20).length;
                    let finalCount = Math.min(5 - countTwenty + 1, 5);
                    if (countTwenty > 3) finalCount = countTwenty;
                    participants = participants.slice(finalCount);

                    // Group by Pushpa Completed count
                    const grouped = {};
                    participants.forEach(person => {
                        const pushpaCount = Number(person[5]);
                        if (!grouped[pushpaCount]) grouped[pushpaCount] = [];
                        grouped[pushpaCount].push(person);
                    });

                    // Get pushpa counts sorted in descending order
                    const pushpaCounts = Object.keys(grouped).map(Number).sort((a, b) => b - a);

                    this.setState(
                        { participantsGrouped: grouped, pushpaCounts },
                        () => this.showNextBatch()
                    );
                }
            },
            (error) => {
                this.setState({
                    showList: false,
                });
            }
        );
    }

    showNextBatch() {
        const { participantsGrouped, currentIndex, currentPushpaIndex, pushpaCounts } = this.state;

        if (currentPushpaIndex >= pushpaCounts.length) {
            this.fetchAll(this.state.gender);
            return; // Restart from the first group
        }

        const pushpaCount = pushpaCounts[currentPushpaIndex];
        const participants = participantsGrouped[pushpaCount];

        if (!participants || participants.length === 0) {
            this.setState(
                { currentIndex: 0, currentPushpaIndex: currentPushpaIndex + 1 },
                () => this.showNextBatch()
            );
            return;
        }

        const batch = participants.slice(currentIndex, currentIndex + 8);
        if (batch.length === 0) {
            // Move to next pushpa group if no more batches in current group
            this.setState(
                { currentIndex: 0, currentPushpaIndex: currentPushpaIndex + 1 },
                () => this.showNextBatch()
            );
        } else {
            this.setState({ currentBatch: [] }, () => {
                this.setState({ currentBatch: batch, currentIndex: this.state.currentIndex + 8 });
            });

            // Show next batch after 10 seconds
            setTimeout(this.showNextBatch, 10000);
        }
    };

    getDriveFileId(url) {
        const urlParams = new URLSearchParams(new URL(url).search);
        return urlParams.get("id");
    }


    render() {
        return (
            <div className="mukpathPage d-flex flex-column" style={{ height: "100vh" }}>
                {/* Navbar */}
                <Navbar collapseOnSelect expand="lg" variant="dark">
                    <Container>
                        <Navbar.Brand>
                            <img alt="BAPS Swaminarayan Sanstha, Chicago, IL" src={logo} className="title-image" />
                        </Navbar.Brand>
                        <Navbar id="responsive-navbar-nav" className="justify-content-end">
                            <Nav>
                                {(this.state.viewType === 1 || this.state.viewType === 2) && <Nav.Link>Rajipo Board</Nav.Link>}
                                {(this.state.viewType === 3 || this.state.viewType === 4) && <Nav.Link>{this.state.pushpaCounts[this.state.currentPushpaIndex]} Pushpa Completed</Nav.Link>}
                            </Nav>
                        </Navbar>
                    </Container>
                </Navbar>

                {/* Main Content Row */}
                {
                    (this.state.viewType === 1 || this.state.viewType === 2) && this.state.showList && this.state.participantsList.length > 1 && this.state.countTwenty <= 3 &&
                    <Row className="contentCont flex-grow-1" style={{ height: "calc(100vh - 56px)" }}>
                        {this.state.participantsList.slice(0, this.state.countTwenty > 0 ? this.state.countTwenty : 1).map((participant, index) => {
                            return (
                                <Col xs={4} className="d-flex flex-column" key={participant[0]}>
                                    <div className="mainCard custom-backdrop">
                                        <div className="badge"><span>{participant[5]} Pushpa</span></div>
                                        <Col>
                                            <div className="subCards">
                                                {participant[6] ? 
                                                    <img className="profile-image custom-backdrop" src={`https://lh3.googleusercontent.com/d/${this.getDriveFileId(participant[6])}=s720`} alt={participant[0]} />
                                                :
                                                    <img className="profile-image custom-backdrop" src={mukpathDummy} alt={participant[0]} />
                                                }
                                                <img src={floral2} className="floral" alt="floral border" />
                                                <h2>
                                                    {participant[0]}
                                                    {participant[3] && <>
                                                        <br />
                                                        <span>{participant[3]}</span>
                                                    </>}
                                                </h2>
                                                
                                            </div>
                                        </Col>
                                    </div>
                                </Col>
                        )})}
                        
                        {this.state.countTwenty < 3 &&
                            <Col className="d-flex flex-column">
                                <Row className="flex-grow-1">
                                    {this.state.participantsList.slice(this.state.countTwenty > 0 ? this.state.countTwenty : 1).map((participant, index) => {
                                        return (
                                            <Col xs={this.state.countTwenty > 1 ? 12 : 6} key={participant[0]}>
                                                <div className="subCards">
                                                    {participant[6] ?
                                                        <img className="profile-image custom-backdrop" src={`https://lh3.googleusercontent.com/d/${this.getDriveFileId(participant[6])}=s720`} alt={participant[0]} />
                                                        :
                                                        <img className="profile-image custom-backdrop" src={mukpathDummy} alt={participant[0]} />
                                                    }
                                                    <h2>
                                                        {participant[0]}
                                                        <br />
                                                        <span>{participant[5]} Pushpa
                                                            {participant[3] && <>
                                                                &nbsp;&#9679;&nbsp;{participant[3]}
                                                            </>}
                                                        </span>
                                                    </h2>
                                                </div>
                                            </Col>
                                        );
                                    })}
                                </Row>
                            </Col>
                        }
                    </Row>
                }

                {
                    (this.state.viewType === 1 || this.state.viewType === 2) && this.state.showList && this.state.participantsList.length > 1 && this.state.countTwenty > 3 &&
                    <Row className="contentCont flex-grow-1" style={{ height: "calc(100vh - 56px)" }}>
                        {this.state.rotatingTwenty.map((participant, index) => (
                            <Col xs={4} className="d-flex flex-column" key={participant[0]}>
                                <div className="mainCard custom-backdrop">
                                    <div className="badge"><span>{participant[5]} Pushpa</span></div>
                                    <Col>
                                        <div className="subCards">
                                            {participant[6] ? 
                                                <img className="profile-image custom-backdrop" src={`https://lh3.googleusercontent.com/d/${this.getDriveFileId(participant[6])}=s720`} alt={participant[0]} />
                                            :
                                                <img className="profile-image custom-backdrop" src={mukpathDummy} alt={participant[0]} />
                                            }
                                            <img src={floral2} className="floral" alt="floral border" />
                                            <h2>
                                                {participant[0]}
                                                {participant[3] && <>
                                                    <br />
                                                    <span>{participant[3]}</span>
                                                </>}
                                            </h2>
                                            
                                        </div>
                                    </Col>
                                </div>
                            </Col>
                        ))}
                    </Row>
                }
                

                {/* Main Content Row */}
                {
                    (this.state.viewType === 3 || this.state.viewType === 4) &&
                    <Row className="contentCont flex-grow-1" style={{ height: "calc(100vh - 56px)" }}>
                        <Col className="d-flex flex-column">
                            <Row className="flex-grow-1">
                                {this.state.currentBatch.map((participant, index) => {
                                    return (
                                        <Col xs={3} key={participant[0]}>
                                            <div className="subCards">
                                                {participant[6] ? 
                                                    <img className="profile-image custom-backdrop" src={`https://lh3.googleusercontent.com/d/${this.getDriveFileId(participant[6])}=s720`} alt={participant[0]} />
                                                :
                                                    <img className="profile-image custom-backdrop" src={mukpathDummy} alt={participant[0]} />
                                                }
                                                <h2>
                                                    {participant[0]}
                                                    <br/>
                                                    <span>{participant[3] ? participant[3] : <br/>}</span>
                                                </h2>
                                            </div>
                                        </Col>
                                    );
                                })}
                            </Row>
                        </Col>
                    </Row>
                }

                {/* Image Slide for Rajipo Board */}
                {
                    (this.state.viewType === 1 || this.state.viewType === 2) &&
                    <img className={`slideImage ${this.state.showImage ? 'fade-in' : 'fade-out'}`} src={this.state.viewType === 1 ? mukpathPath1 : mukpathPath2} alt="Mukpath Path" />
                }
                {/* Footer */}
                
            </div>
        );
    }
}

export default withParams(MukpathView);
