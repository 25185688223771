// Vendor Files
import React from "react";
import { useParams } from "react-router";
import { Container, Tabs, Tab } from 'react-bootstrap';
import { HouseFill, TrophyFill, CalendarCheckFill, Table, House } from 'react-bootstrap-icons';

// Custom Components
import Header from "../components/Header"
import Footer from "../components/Footer"

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

class YogiCup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <div>

                <Header metaPage="yogicup"></Header>

                <div className="sectionCont">
                    <Container>
                        <Tabs defaultActiveKey="home" id="fill-tab-example" className="mb-3" fill >
                            <Tab eventKey="home" title={<HouseFill/>}>
                                Tab content for Contact
                            </Tab>
                            <Tab eventKey="results" title={<TrophyFill/>}>
                                Tab content for Contact
                            </Tab>
                            <Tab eventKey="schedule" title={<CalendarCheckFill/>}>
                                Tab content for Contact
                            </Tab>
                            <Tab eventKey="contact" title={<Table/>}>
                                Tab content for Contact
                            </Tab>
                        </Tabs>
                    </Container>
                </div>


                <Footer hideConnected="true"></Footer>
            </div>
        );
    }
}

export default withParams(YogiCup);
