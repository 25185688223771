import React from "react";
import Helmet from "react-helmet";

const MetaDecorator = ({ title, description, keywords, url, imageURL }) => {
    return (
        <Helmet>
            <meta charSet="utf-8" />
            <title>{title}</title>
            <meta property="og:title" content={title} />
            <meta name="description" content={description} />
            <meta property="og:description" content={description} />
            <meta property="og:description" content={description} />
            <meta name="keywords" content={keywords} />
            <link rel="canonical" href={url} />
            <meta property="og:url" content={url} />
            <meta property="og:type" content="website" />
            <meta property="og:image" content={imageURL} />
        </Helmet>   
    )
};

export default MetaDecorator;