// Vendor Files
import React from "react";
import { Container, Row, Col, Button, Navbar, Nav, Tabs, Tab } from 'react-bootstrap';
import { useParams } from "react-router";

// Custom Components
import Footer from "../components/Footer"
import MainForm from "../components/Forms/mainForm";

// Util
import MetaDecorator from "../util/MetaDecorator";
import { MetaStrings } from "../util/MetaStrings";

// Image Files
import telegram_logo from "../assets/logos/telegram.png";
import logo from "../assets/logos/midwest-logo.png";
import clock_icon from "../assets/images/clock-icon.png";
import location_icon from "../assets/images/location-icon.png";
import ticket_icon from "../assets/images/ticket.png";
import psm_quote from "../assets/images/psm_quote.png";

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

class StudentActivities extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sessionsList: [],
            sessionsError: false,
            formData: {},
            showForm: false
        };
        this.hideForm = this.hideForm.bind(this);
    }

    componentDidMount() {
        this.fetchSessions();
    }

    fetchSessions() {
        fetch(
            "https://sheets.googleapis.com/v4/spreadsheets/1afaPBZlesuCSW1k1jsUyptxs0t68a0Lzv6g-pYwKjEE/values/Sabha%20List?key=AIzaSyBv5LPMYVjJ0gttyVyROpQD5xO0nvMy2So"
        )
        .then((res) => res.json())
        .then(
            (result) => {
                if (result.values.length > 1) {
                    let sessions = result.values;
                    sessions.shift();
                    this.setState({sessionsList: sessions});

                    // Check RSVP
                    let { university_name } = this.props.params;
                    if (university_name) {
                        let sel_event = sessions.find(event => event[9].toLowerCase() === university_name.toLowerCase() && event[10] === "Yes" && event[11] === "Yes");
                        if (sel_event) {
                            this.openRegistrationForm(sel_event);
                        }
                    }
                }
            },
            (error) => {
                this.setState({
                    sessionsError: true,
                });
            }
        );
    }

    openRegistrationForm(event) {
        this.setState({formData: {
            'formName': 'rsvpStudentSabha',
            'eventId': 'student-sabha-' + event[0].substring(0,3).toLowerCase() + '-' + event[1].substring(2).toLowerCase(),
            'eventName': 'Student Sabha',
            'displayName': 'Student Sabha',
            'description': "Fill out form below to register for the event. We look forward to seeing you.",
            'location': event[6],
            'date': event[3],
            'time': event[4],
            'eventDays': [event[3] + ' - ' + event[4]]
        }, showForm: true}, () => {
            console.log(this.state.formData);
        });
    }

    hideForm() {
        this.setState({formData: {}, showForm: false});
    }

    render() {
       
        return (
            <div>
                <MetaDecorator 
                    title={MetaStrings.studentActivities.title}
                    description={MetaStrings.studentActivities.description}
                    keywords={MetaStrings.studentActivities.keywords}
                    url={MetaStrings.studentActivities.url}
                    imageURL={MetaStrings.studentActivities.imageURL}
                >
                </MetaDecorator>

                <Navbar collapseOnSelect expand="lg" variant="dark" fixed="top">
                    <Container>
                        <Navbar.Brand href="/student-activities">
                            <img alt="" src={logo} className="title-image" />
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
                            <Nav>
                                <Nav.Link href="#about-us">About Us</Nav.Link>
                                <Nav.Link href="#events">Events</Nav.Link>
                                <Nav.Link href="#weekly-sabha">Weekly Sabha</Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>

                <div className="headerSection">
                    <div className="headerContent">
                        <p>BAPS Chicago Welcomes You to</p>
                        <h1>BAPS Student Fellowship</h1>
                        <p>A Pathway to Academic Success and Spiritual Enlightenment</p>
                    </div>
                </div>

                <div className="sectionCont" id="about-us">
                    <Container>
                        <h2>About Us</h2>
                        <p>The BAPS Student Fellowship is a nurturing community committed to supporting students in their academic and personal development. Rooted in the teachings of BAPS, we strive to create an environment that balances educational achievements with spiritual enrichment. <b>Initiated by BAPS, our fellowship is inspired by its profound tradition of nurturing spiritual development and community service.</b></p>
                    </Container>
                </div>

                <div className="divider-line"></div>

                <div id="events" className="sectionCont">
                    <Container>
                        <h2>Events</h2>
                        <p>We hold a monthly campus sabha for students residing near the <b>Illinois Institute of Technology</b>. This sabha provides a platform for students to engage in spiritual discussions, receive academic guidance, and build a supportive community. The exact date, time, and location are communicated through our BAPS Student Fellowship Telegram group.</p>
                        
                        <Row>
                            <Col>
                                <a href="https://t.me/+-zXUbEtoZuM3ODZh" target="_blank" rel="noreferrer"
                                    style={{
                                        color: "#136cce",
                                        textDecoration: "none",
                                        display: "inline-block"
                                    }}
                                >
                                    <div style={{ display: "flex" }}>
                                        <h3 style={{ color: "#1b1e2f" }}>Join the Telegram Group:</h3>
                                        <img
                                            className="whatsapp-app-icon"
                                            srcSet={telegram_logo + " 1280w"}
                                            sizes="300px"
                                            src={telegram_logo}
                                            alt="Share via Telegram"
                                        />
                                    </div>
                                </a>
                            </Col>
                        </Row>
                        <br />
                        <br />
                        <div className={"gridCont sessions-tab"}>
                            {this.state.sessionsList.length > 0 ?
                            <Tabs id="noanim-tab-example">
                                {this.state.sessionsList.map((event, index) => {
                                    return event[11] === "Yes" ?
                                        <Tab eventKey={`${event[0]} ${event[1]}`} title={`${event[0]} ${event[1]}`}>
                                            <Row>
                                                <Col md={6} className="">
                                                    <h3>{event[2]}</h3>
                                                    <p>
                                                        <b>Date:</b> {event[3]} <br/>
                                                        <b>Time:</b> {event[4]} <br/>
                                                        <b>Speaker:</b> {event[5]} <br/>
                                                        <b>Location:</b> {event[6]} <br/>
                                                    </p>
                                                    <p>
                                                        <b>About This Session:</b> <br/>
                                                        {event[7]}
                                                    </p>
                                                    {event[10] === "Yes" ? 
                                                        <Button target="_blank" onClick={() => {this.openRegistrationForm(event)}}>Register Now</Button>
                                                    : ""}
                                                    <br/><br/>
                                                </Col>
                                                <Col md={6} className="centerCont">
                                                    <div>
                                                        <img className="grid-event-img" src={`https://lh3.googleusercontent.com/d/${event[8]}=s720`} alt={event[2]} />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Tab>
                                    : ""
                                })}
                            </Tabs>
                            : 
                            <p>{this.state.sessionsError ? 
                                "We are having issues fetching upcoming sessions. Please try again later." : ""
                            }</p>
                        }
                        </div>
                    </Container>
                </div>

                <div className="divider-line"></div>

                <div id="weekly-sabha" className="sectionCont">
                    <Container>
                        <h2>Weekly Student Sabha</h2>
                        <p>In addition to the monthly sabhas, we organise a weekly student sabha at the BAPS Bartlett Mandir. This weekly gathering provides continuous support and spiritual nourishment for students. <b>We also offer transportation from downtown Chicago to the mandir to ensure that all students can participate.</b> The weekly sabha includes various activities such as discourses, group discussions, and mentoring sessions to help students stay connected and grow in their academic and spiritual journey.</p>
                        <div className={"gridCont visitorCont"}>
                            <Row className="sessionsInfoCont">
                                <Col md={4} className="">
                                    <img alt="" src={clock_icon} />
                                    <h3>Timing</h3>
                                    <p>
                                        Every Saturday <br /> 
                                        4:00 p.m. to 5:15 p.m. <br />
                                        Followed by Mahaprasad (Dinner)
                                    </p>
                                </Col>
                                <Col md={4} className="">
                                    <img alt="" src={location_icon} />
                                    <h3>Address</h3>
                                    <p>
                                        BAPS Shri Swaminarayan Mandir <br />
                                        1851 S IL Route 59 <br /> 
                                        Bartlett, Illinois 60103
                                    </p>
                                </Col>
                                <Col md={4} className="">
                                    <img alt="" src={ticket_icon} />
                                    <h3>Free Entry</h3>
                                    <p>Students of all faiths and backgrounds are welcome to participate, learn, grow, and connect with fellow students</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <a href="https://t.me/+THimg_OcrOdhOTA5" target="_blank" rel="noreferrer"
                                    style={{
                                        color: "#136cce",
                                        textDecoration: "none",
                                        display: "inline-block"
                                    }}
                                    >
                                    <div style={{ display: "flex" }}>
                                        <h3 style={{ color: "#1b1e2f" }}>Join Telegram for Saturday Mandir Ride:</h3>
                                        <img
                                        className="whatsapp-app-icon"
                                        srcSet={telegram_logo + " 1280w"}
                                        sizes="300px"
                                        src={telegram_logo}
                                        alt="Share via Telegram"
                                        />
                                    </div>
                                    </a>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>

                <div className="parallaxSection">
                    <Container className="bannerCont">
                        <img src={psm_quote} alt="In the joy of others lies our own, In the progress of others rests our own, In the good of others abides our own, Know this to be the key to peace and happiness" />
                    </Container>
                </div>

                <Footer></Footer>

                {this.state.showForm && <MainForm formName={this.state.formData.formName} formData={this.state.formData} hideForm={this.hideForm}></MainForm>}
            </div>
        );
    }
}

export default withParams(StudentActivities);
