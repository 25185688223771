// Vendor Files
import React from "react";
import { useParams } from "react-router";

// Custom Components

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

class Mukpath extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
        this.timer = setTimeout(() => {
          window.location.reload();
        }, 14400000); // 4 hours in milliseconds
    }
    
    componentWillUnmount() {
        clearTimeout(this.timer); // Cleanup timeout to prevent issues
    }

    render() {
        return (
            <div>
                <iframe
                    src="/mukpath-view#e-leaderboard"
                    width="50%"
                    height="50%"
                    title="ifram1"
                    style={{ position: "fixed", top: "0", left: "0", right: "0", bottom: "0", border: "none", margin: "0", padding: "0", overflow: "hidden", "z-index": "999999" }}
                />
                <iframe
                    src="/mukpath-view#i-leaderboard"
                    width="50%"
                    height="50%"
                    title="ifram2"
                    style={{ position: "fixed", top: "0", right: "0", bottom: "0", border: "none", margin: "0", padding: "0", overflow: "hidden", "z-index": "999999" }}
                />
                <iframe
                    src="/mukpath-view#e-mukpath"
                    width="50%"
                    height="50%"
                    title="ifram3"
                    style={{ position: "fixed", left: "0", right: "0", bottom: "0", border: "none", margin: "0", padding: "0", overflow: "hidden", "z-index": "999999" }}
                />
                <iframe
                    src="/mukpath-view#i-mukpath"
                    width="50%"
                    height="50%"
                    title="ifram4"
                    style={{ position: "fixed", right: "0", bottom: "0", border: "none", margin: "0", padding: "0", overflow: "hidden", "z-index": "999999" }}
                />
            </div>
        );
    }
}

export default withParams(Mukpath);
